import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { TemplateProps } from "@/Templates/TemplateProps";
import dynamic from 'next/dynamic';
import { useContext, useEffect, useMemo } from "react";
const LandingPage = () => {
  const {
    merchantId,
    isLogin,
    deviceType,
    messageApi,
    notificationApi,
    setMaxWidth,
    setIsHideFunctionalFloatButtonGroup,
    setIsHideNavBar,
    setIsHideFooter,
    merchantPortalOptionSettingMutation,
    merchantPortalStyleSettingsMutation,
    initAppMutation,
    isHideFooter,
    navBarHeight,
    navBarGap
  } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);

  useEffect(() => {
    setMaxWidth('100%');
    setIsHideFunctionalFloatButtonGroup(true);
    return () => {
      setIsHideFunctionalFloatButtonGroup(false);
      setIsHideNavBar(false);
      setIsHideFooter(false);
      setMaxWidth('1340px');
    }
  }, []);

  useEffect(() => {
    setIsHideNavBar(true);
    return () => {
      setIsHideNavBar(false);
    }
  }, [initAppMutation.isLoading]);

  useEffect(() => {
    if (!isHideFooter) {
      setIsHideFooter(true);
    }
  }, [isHideFooter]);

  const TemplateComponent = useMemo(() => {
    if (merchantPortalOptionSettingMutation.data?.result?.isCustomLandingPage || merchantPortalOptionSettingMutation.data?.result?.isCustomPortalPage) {
      return null;
    }

    return dynamic<TemplateProps>(() =>
      import(`../../Templates/Shop/Pages/LandingPage/Template_${merchantPortalStyleSettingsMutation.data?.result?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.style || '0'}.tsx`),
      {
        loading: () => <LoadingComponent />,
        ssr: false
      }
    );
  }, [merchantPortalOptionSettingMutation.data?.result, merchantPortalStyleSettingsMutation.data?.result]);

  // 處理 meta tags
  useEffect(() => {
    const metaTags: { name?: string; property?: string; content: string }[] = [];

    // 如果有商品名稱，加入 title meta tag
    if (merchantPortalOptionSettingMutation.data?.result?.merchantName) {
      metaTags.push({ property: 'og:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Landing Page')}` });
      metaTags.push({ name: 'twitter:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Landing Page')}` });
      metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
      metaTags.push({ name: 'twitter:url', content: window.location.origin });
    }
    if (merchantPortalOptionSettingMutation.data?.result?.merchantIconPath) {
      metaTags.push({ property: 'og:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
      metaTags.push({ name: 'twitter:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
    }

    // 只有在有 meta tags 時才執行
    if (metaTags.length > 0) {
      const addedTags = metaTags.map(tag => {
        const metaTag = document.createElement('meta');
        if (tag.property) {
          metaTag.setAttribute('property', tag.property);
        }
        if (tag.name) {
          metaTag.setAttribute('name', tag.name);
        }
        metaTag.setAttribute('content', tag.content || '');
        document.head.appendChild(metaTag);
        return metaTag;
      });

      return () => {
        addedTags.forEach(tag => document.head.removeChild(tag));
      };
    }
  }, [merchantPortalOptionSettingMutation.data?.result, translate]);

  if (!TemplateComponent || !merchantPortalOptionSettingMutation.data?.result || merchantPortalOptionSettingMutation.isLoading || merchantPortalStyleSettingsMutation.isLoading) {
    return <LoadingComponent />;
  }

  return (
    <TemplateComponent
      navBarHeight={navBarHeight}
      navBarGap={navBarGap}
      deviceType={deviceType}
      BASE_PATH={BASE_PATH}
      messageApi={messageApi}
      notificationApi={notificationApi}
      translate={translate}
      merchantId={merchantPortalOptionSettingMutation.data?.result?.merchantId}
      merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
      merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
      config={merchantPortalStyleSettingsMutation.data?.result?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.styleSetting || {}}
    />
  );
};

export default LandingPage;