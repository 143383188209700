import { GetOrdersStatusCode } from "@/enums/StatusCodes/StatusCode";
import { IOrder } from "@/interfaces/Responses";
import { useGetLastestThirdPartyPaymentOrderApi, useGetLogisticSupportPaymentSettingIdsApi, useGetMemberOrdersApi, useGetNoRegistOrderApi, useGetOrderHistoryDetailViewModelApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { TimeHelper } from "@/Templates/helpers/TimeHelper";
import PaymentSelector from "@/Templates/Shop/Pages/ShoppingCartPage/Components/PaymentSelector";
import { CheckOutContext, useCheckOut } from "@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Button, Flex, Modal, Skeleton } from "antd";
import React, { useContext, useImperativeHandle, useState } from "react";
import { useQuery } from "react-query";

export interface IOrdersProps {
    orderId: string | undefined;
}
export interface IOrdersRef {
    onRefresh: () => void;
}

const PaymentModal = ({ visible, onClose, content, translate }: { visible: boolean; onClose: () => void; content: React.ReactNode; translate: (key: string) => string; }) => {
    return (
        <Modal
            title={translate('Payment')}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            {content}
        </Modal>
    );
};

const Row = (props: { row: IOrder, onRowClick: (id: BigInt) => void, defaultOpenId: BigInt }) => {
    const {
        paymentMainTypesMutation,
        selectedPayment,
        setSelectedPayment,
        setPaymentValues,
        paymentValues,
        sendPaymentRequest,
        isOnlinePayment,
        setIsOnlinePayment,
        merchantPortalPaymentSettingsMutation,

    } = useCheckOut();
    const {
        messageApi,
        merchantId,
        isLogin
    } = useContext(GlobalContext);
    const { row, onRowClick, defaultOpenId } = props;
    const [open, setOpen] = useState(row.id.toString() === defaultOpenId.toString());
    const { translate } = useContext(TranslationContext);
    const [isModalVisible, setModalVisible] = useState(false);

    const paymentSeletor = (order: IOrder) => <PaymentSelector
        isLogin={isLogin}
        isHideSteps={true}
        nextStep={() => { }}
        prevStep={() => { }}
        translate={translate}
        messageApi={messageApi}
        merchantId={merchantId}
        orderId={order.id}
        paymentMainTypesMutation={paymentMainTypesMutation}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        setPaymentValues={setPaymentValues}
        paymentValues={paymentValues}
        merchantPortalPaymentSettingsMutation={merchantPortalPaymentSettingsMutation}
        sendPaymentRequest={sendPaymentRequest}
        isOnlinePayment={isOnlinePayment}
        setIsOnlinePayment={setIsOnlinePayment}
    />

    const handleRetryClick = () => {
        setModalVisible(true);
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    backgroundColor: '#f5f5f5',
                    '&:hover': {
                        backgroundColor: '#eeeeee'
                    }
                }}
                onClick={() => {
                    setOpen(!open);
                    onRowClick(row.id);
                }}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                    <Typography
                        variant="body2"
                        sx={{ cursor: 'pointer' }}
                    >
                        {row.id.toString()}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    {TimeHelper.formatUtcStringToLocal(row.createdTime)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: 1,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            padding: 0
                        }}>
                            <Table
                                size="small"
                                sx={{
                                    '& th': {
                                        fontWeight: 'bold'
                                    }
                                }}
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            align="center"
                                        >
                                            {translate('Total price')}
                                        </TableCell>
                                        <TableCell align="center">{row.totalFinalPrice}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            align="center"
                                        >
                                            {translate('Paid')}
                                        </TableCell>
                                        <TableCell align="center">{row.isPaid ? translate('Yes') : translate('No')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            align="center"
                                        >
                                            {translate('Order Status')}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            align="center"
                                        >
                                            {row.status === 'WaitForPaid' ? (
                                                <Flex justify="center" align="center" gap="10px">
                                                    <label style={{ color: 'red' }}>{translate(row.status)}</label>
                                                    <Button onClick={handleRetryClick}>
                                                        {translate('Retry')}
                                                    </Button>
                                                </Flex>
                                            ) : (
                                                <>{translate(row.status)}</>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    {/* 訂單備註  如果備註有值，則顯示 */}
                                    {row.note && (
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                align="center"
                                            >
                                                {translate('Order Notes')}
                                            </TableCell>
                                            <TableCell align="center">{row.note}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <Table
                                size="small"
                                sx={{
                                    '& th': {
                                        fontWeight: 'bold'
                                    }
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{translate('Image')}</TableCell>
                                        <TableCell align="center">{translate('Name')}</TableCell>
                                        <TableCell align="center">{translate('SpecName')}</TableCell>
                                        <TableCell align="center">{translate('Sell Price')}</TableCell>
                                        <TableCell align="center">{translate('Buy Amount')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((item) => (
                                        <TableRow key={item.itemId.toString()}>
                                            <TableCell align="center">
                                                <img
                                                    src={item.itemIcon}
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '4px'
                                                    }}
                                                    alt={item.itemName}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{item.itemName}</TableCell>
                                            <TableCell align="center">{item.itemSpecName}</TableCell>
                                            <TableCell align="center">{item.itemSpecPrice}</TableCell>
                                            <TableCell align="center">{item.buyAmount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <PaymentModal
                visible={isModalVisible}
                translate={translate}
                onClose={() => {
                    setModalVisible(false);
                    setSelectedPayment(undefined);
                    setPaymentValues({});
                }}
                content={paymentSeletor(row)}
            />
        </>
    );
};

const Orders = React.forwardRef((props: IOrdersProps, ref: React.ForwardedRef<IOrdersRef | undefined>) => {
    const {
        setLogiscticSupportedPaymentSettingIds,
    } = useContext(CheckOutContext);
    const { deviceType, merchantId, messageApi, tryGetSessionId, isLogin } = useContext(GlobalContext);
    const { data: orders, refetch, isLoading: isLoadingOrders } = useQuery(
        "GetMemberOrders",
        isLogin ?
            () => useGetMemberOrdersApi() :
            () => useGetNoRegistOrderApi(BigInt(props.orderId || '0')),
        { enabled: props.orderId !== undefined || isLogin }
    );
    const { translate } = useContext(TranslationContext);

    const handleRowClick = async (id: BigInt) => {
        const order = orders?.result?.data.find((order) => order.id.toString() === id.toString());
        if (!order) {
            messageApi.error("Failed to get order detail");
            return;
        }

        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('orderId', id.toString());
        window.history.pushState({}, '', newUrl.toString());

        const orderHistoryDetailVM = await useGetOrderHistoryDetailViewModelApi(order.id);
        if (!orderHistoryDetailVM.isSuccess) {
            messageApi.error("Failed to get order vm detail");
            return;
        }

        const getSupportPaymentSettingIdsAction = await useGetLogisticSupportPaymentSettingIdsApi(orderHistoryDetailVM.result?.memberLogisticOption?.thirdPartyLogisticId || BigInt(0));
        const paymentDetail = await useGetLastestThirdPartyPaymentOrderApi({
            orderId: order.id,
            merchantId: merchantId,
            sessionId: tryGetSessionId(),
        });

        if (!paymentDetail.isSuccess && paymentDetail.errorModel.status !== GetOrdersStatusCode.OrderNotFound) {
            messageApi.error("Failed to get payment detail");
        }

        if (getSupportPaymentSettingIdsAction.isSuccess && getSupportPaymentSettingIdsAction.result) {
            setLogiscticSupportedPaymentSettingIds(getSupportPaymentSettingIdsAction.result);
        }
    };

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            refetch();
        }
    }), [orders]);

    const defaultOpenId = BigInt(props.orderId || '0');

    if (isLoadingOrders) {
        return <Skeleton />;
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" />
                        <TableCell align="center">{translate('Order ID')}</TableCell>
                        <TableCell align="center">{translate('Create time')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders?.result?.data.map((order) => (
                        <Row
                            key={order.id.toString()}
                            row={order}
                            onRowClick={handleRowClick}
                            defaultOpenId={defaultOpenId} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default Orders;
