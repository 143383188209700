import domainLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { BASE_PATH } from '@/constants/routes';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import FullPageLoading from '@/Templates/components/FullPageLoading';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import NavBar from '@/Templates/components/NavBar';
import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zhTW from 'antd/locale/zh_TW';
import React, { useContext, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useGetMerchantIsExpiredApi } from '../../lib/api/apis';
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import '../../Templates/assets/fonts/fonts.css';
import MerchantClosedPage from '../MerchantClosedPages/MerchantClosedPage';
import AppFooter from './Components/AppFooter';
import FunctionalFloatButtonGroup from './Components/FunctionalFloatButtonGroup';
import MyRoutes from './Components/MyRoutes';


const App: React.FC = () => {
  const globalContext = useContext(GlobalContext);
  const translationContext = useContext(TranslationContext);
  const navigate = useNavigate();

  // 檢查兩個 context 是否都已經準備好
  if (!globalContext || !translationContext) {
    return <LoadingComponent />;
  }

  const {
    maxWidth,
    messageApi,
    notificationApi,
    merchantId,
    shoppingCartItemCount,
    isFullPageLoading,
    setIsFullPageLoading,
    merchantPortalStyleSettingsMutation,
    navBarHeight,
    navBarGap,
    isHideNavBar,
    merchantPortalOptionSettingMutation,
    setNavBarHeight,
    setNavBarGap,
    deviceType,
    setDeviceType,
    isLogin,
    setIsLogin,
    refreshShoppingCartItemCount,
    setIsHideFooter,
    initAppMutation
  } = globalContext;
  const { translate,
    i18nLanguage } = translationContext;
  const { isLoading: isExpiredLoading, data: isExpiredValue } = useQuery('IsExpired', async () => await useGetMerchantIsExpiredApi(merchantId), {
    onError: (error) => {
      messageApi.error('Unknown error');
    }
  });

  useEffect(() => {
    initAppMutation.mutate({ merchantId: merchantId });
  }, []);

  useEffect(() => {
    if (isExpiredValue?.result) {
      navigate(`${BASE_PATH}/merchantClosed`);
    }
  }, [isExpiredValue]);


  const [locale, setLocale] = React.useState<Locale>(enUS);

  // Function to detect device type using visualViewport and userAgent
  const detectDeviceType = () => {
    const viewportWidth = window.visualViewport?.width || window.innerWidth;

    // Fallback to user agent for additional detection
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);

    if (viewportWidth <= 768 || isMobile || isIOS) {
      setDeviceType(DeviceType.Mobile);
      setNavBarHeight(50);
      setNavBarGap(50);
    } else if (viewportWidth <= 1024 || isTablet || isAndroid) {
      setDeviceType(DeviceType.LapTop);
      setNavBarHeight(60);
      setNavBarGap(60);
    } else {
      setDeviceType(DeviceType.Desktop);
      setNavBarHeight(60);
      setNavBarGap(60);
    }
  };

  useEffect(() => {
    // Initialize device type detection
    detectDeviceType();

    // Add event listeners for resize and orientation change
    const handleResize = () => detectDeviceType();
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (i18nLanguage) {
      case 'zh_TW':
        setLocale(zhTW);
        break;
      case 'zh_CN':
        setLocale(zhCN);
        break;
      default:
        setLocale(enUS);
        break;
    }
  }, [i18nLanguage]);

  useEffect(() => {
    setIsFullPageLoading(false);
  }, []);

  if (initAppMutation.isLoading || isExpiredLoading) {
    return <></>
  }
  if (isExpiredValue?.result || merchantPortalOptionSettingMutation.data?.result?.isClosed) {
    return <MerchantClosedPage />
  }

  return (
    !isExpiredValue?.result && (
      <>
        <Helmet>
          <meta charSet="UTF-8" />
          <link rel="icon" type="image/*" href={merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath || merchantPortalOptionSettingMutation.data?.result?.merchantIconPath || domainLogo} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{merchantPortalOptionSettingMutation.data?.result?.merchantName}</title>
        </Helmet>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: "orange",
            },
            components: {
              FloatButton: {
                colorPrimary: "white",
                colorPrimaryHover: "whitesmoke",
              }
            }
          }}
        >
          {/* Full Page Loading */}
          {/* <FullPageLoading
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            BASE_PATH={BASE_PATH}
            isFullPageLoading={isFullPageLoading}
            deviceType={deviceType}
            translate={translate}
            messageApi={messageApi}
            notificationApi={notificationApi}
            merchantId={merchantId}
            config={merchantPortalStyleSettingsMutation.data?.result?.filter(r => r.type === MerchantPortalStyleSettingType.Components && r.name === MerchantPortalStyleShopComponents.FullPageLoading)[0]?.styleSetting || {}}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
          /> */}

          {/* NavBar */}
          <NavBar
            navBarGap={navBarGap}
            navBarHeight={navBarHeight}
            BASE_PATH={BASE_PATH}
            shoppingCartItemCount={shoppingCartItemCount}
            isLogin={isLogin}
            setIsLogin={setIsLogin}
            refreshShoppingCartItemCount={refreshShoppingCartItemCount}
            isHideNavBar={isHideNavBar}
            setNavBarHeight={setNavBarHeight}
            setNavBarGap={setNavBarGap}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            translate={translate}
            messageApi={messageApi}
            notificationApi={notificationApi}
            merchantId={merchantId}
            config={merchantPortalStyleSettingsMutation.data?.result?.filter(r => r.type === MerchantPortalStyleSettingType.Components && r.name === MerchantPortalStyleShopComponents.NavBar)[0]?.styleSetting || {}}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
          />
          {/* Page Content max width 1024 px*/}
          <div style={{ maxWidth: maxWidth, margin: '0 auto' }}>
            <MyRoutes />
            <FunctionalFloatButtonGroup></FunctionalFloatButtonGroup>
          </div>
        </ConfigProvider>
      </>
    )
  );
};

export default App;
