import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { TemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useMemo } from "react";

const ContactUsPage: React.FC = () => {
    const {
        merchantId,
        messageApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        deviceType,
        isFullPageLoading: isPageLoading,
        notificationApi,
        navBarHeight,
        navBarGap
    } = useContext(GlobalContext);

    const { translate } = useContext(TranslationContext);

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`@/Templates/Shop/Pages/ContactUsPage/Template_0`).then(mod => mod.default),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, []);

    if (isPageLoading) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            deviceType={deviceType}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            messageApi={messageApi}
            notificationApi={notificationApi}
            isPreviewMode={false}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            config={merchantPortalStyleSettingsMutation.data?.result?.find(
                x => x.type === MerchantPortalStyleSettingType.Pages &&
                    x.name === MerchantPortalStyleShopPages.ContactUsPage
            )?.styleSetting}
            translate={translate}
        />
    );
};

export default ContactUsPage;
