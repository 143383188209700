import { BASE_PATH } from "@/constants/routes";
import { useGetItemsByMerchantApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { PagesTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query";


const HomePage: React.FC = React.memo(() => {
  const {
    merchantId,
    deviceType,
    merchantPortalOptionSettingMutation,
    merchantPortalStyleSettingsMutation,
    messageApi,
    notificationApi,
    navBarHeight,
    navBarGap
  } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const { data: merchantPaginationItems, error, isLoading: isLoadingItems, refetch } = useQuery("GetItemsByMerchant", async () => await useGetItemsByMerchantApi({
    merchantId: merchantId,
    page: 1,
    pageSize: 8
  }));

  const portalStyle = merchantPortalStyleSettingsMutation.data?.result?.find(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'HomePage');

  const TemplateComponent = useMemo(() => {
    return dynamic<PagesTemplateProps>(() =>
      import(`../../Templates/Shop/Pages/HomePage/Template_${portalStyle?.style || '0'}.tsx`),
      {
        loading: () => <></>,
        ssr: false
      }
    );
  }, [portalStyle?.style]);

  // 處理 meta tags
  useEffect(() => {
    const metaTags: { property: string; content: string }[] = [];

    // 如果有商品名稱，加入 title meta tag
    if (merchantPortalOptionSettingMutation.data?.result?.merchantName) {
      metaTags.push({ property: 'og:title', content: merchantPortalOptionSettingMutation.data?.result?.merchantName });
    }
    if (merchantPortalOptionSettingMutation.data?.result?.merchantIconPath) {
      metaTags.push({ property: 'og:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
    }

    // 只有在有 meta tags 時才執行
    if (metaTags.length > 0) {
      const addedTags = metaTags.map(tag => {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('property', tag.property);
        metaTag.setAttribute('content', tag.content || '');
        document.head.appendChild(metaTag);
        return metaTag;
      });

      return () => {
        addedTags.forEach(tag => document.head.removeChild(tag));
      };
    }
  }, [merchantPortalOptionSettingMutation.data?.result]);

  return (
    <TemplateComponent
      navBarHeight={navBarHeight}
      navBarGap={navBarGap}
      BASE_PATH={BASE_PATH}
      merchantId={merchantId}
      deviceType={deviceType}
      merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
      merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
      translate={translate}
      messageApi={messageApi}
      notificationApi={notificationApi}
      isPreviewMode={false}
      config={portalStyle?.styleSetting || {}}
      items={merchantPaginationItems?.result}
      isLoading={isLoadingItems}
    />
  );
});

export default HomePage;