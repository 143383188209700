import { BASE_PATH } from "@/constants/routes";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { TemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useEffect, useMemo } from "react";

const PrivacyPolicyPage: React.FC = () => {
    const {
        merchantId,
        messageApi,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        deviceType,
        notificationApi,
        isFullPageLoading,
        navBarHeight,
        navBarGap
    } = useContext(GlobalContext);

    const { translate } = useContext(TranslationContext);

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`@/Templates/Shop/Pages/PrivacyPolicyPage/Template_0`).then(mod => mod.default),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, []);

    // 處理 meta tags
    useEffect(() => {
        const metaTags: { name?: string; property?: string; content: string }[] = [];

        // 如果有商品名稱，加入 title meta tag
        if (merchantPortalOptionSettingMutation.data?.result?.merchantName) {
            metaTags.push({ property: 'og:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Privacy Policy')}` });
            metaTags.push({ name: 'twitter:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Privacy Policy')}` });
            metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
            metaTags.push({ name: 'twitter:url', content: window.location.origin });
        }
        if (merchantPortalOptionSettingMutation.data?.result?.merchantIconPath) {
            metaTags.push({ property: 'og:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
            metaTags.push({ name: 'twitter:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
        }

        // 只有在有 meta tags 時才執行
        if (metaTags.length > 0) {
            const addedTags = metaTags.map(tag => {
                const metaTag = document.createElement('meta');
                if (tag.property) {
                    metaTag.setAttribute('property', tag.property);
                }
                if (tag.name) {
                    metaTag.setAttribute('name', tag.name);
                }
                metaTag.setAttribute('content', tag.content || '');
                document.head.appendChild(metaTag);
                return metaTag;
            });

            return () => {
                addedTags.forEach(tag => document.head.removeChild(tag));
            };
        }
    }, [merchantPortalOptionSettingMutation.data?.result, translate]);

    if (isFullPageLoading) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            deviceType={deviceType}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            messageApi={messageApi}
            notificationApi={notificationApi}
            isPreviewMode={false}
            config={merchantPortalStyleSettingsMutation.data?.result?.find(
                x => x.type === MerchantPortalStyleSettingType.Pages &&
                    x.name === MerchantPortalStyleShopPages.PrivacyPolicyPage
            )?.styleSetting}
            translate={translate}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
        />
    );
};

export default PrivacyPolicyPage;