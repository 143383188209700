import { BASE_PATH } from "@/constants/routes";
import { } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from "@/Templates/enums/templateEnums";
import { SignHelper } from "@/Templates/helpers/SignHelper";
import { IBatchAddItemToShoppingCartRequest, IBatchAddItemToTempShoppingCartRequest, IBatchRemoveItemsFromShoppingCartRequest, IBatchRemoveItemsFromTempShoppingCartRequest, IShoppingCartItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useBatchAddItemsToShoppingCartApi, useBatchAddItemToTempShoppingCartApi, useBatchRemoveItemsFromShoppingCartApi, useBatchRemoveItemsFromTempShoppingCartApi } from "@/Templates/lib/apis";
import { ShoppingCartPageTemplateProps } from "@/Templates/TemplateProps";
import JSONBig from 'json-bigint';
import dynamic from "next/dynamic";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { CheckOutProvider, useCheckOut } from "../../Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext";

const ShoppingCartPage: React.FC = React.memo(() => {
  const signHelper = new SignHelper();
  const {
    merchantId,
    navBarHeight,
    navBarGap,
    deviceType,
    merchantPortalOptionSettingMutation,
    merchantPortalStyleSettingsMutation,
    messageApi,
    notificationApi,
    isLogin,
    tryGetSessionId,
    shoppingCartItemCount,
    refreshShoppingCartItemCount,
    sendGAEvent,
  } = useContext(GlobalContext);
  const { translate,
    getCountrySelectionDisplayName
  } = useContext(TranslationContext);
  const {
    currentStep,
    setCurrentStep,
    nextStep,
    prevStep,
    selectedItems,
    setSelectedItems,
    selectedPayment,
    setSelectedPayment,
    paymentValues,
    setPaymentValues,
    selectedLogistic,
    setSelectedLogistic,
    selectedMemberLogisticOption,
    setSelectedMemberLogisticOption,
    logisticValues,
    setLogisticValues,
    userWantReservePay,
    setUserWantReservePay,
    receiverInfos,
    setReceiverInfos,
    shoppingCartDataMutation,
    paymentMainTypesMutation,
    merchantPortalPaymentSettingsMutation,
    logisticsMainTypes,
    logisticMethods,
    memberLogisticOptionsMutation,
    handleAddMemberLogisticOption,
    handleDeleteMemberLogisticOption,
    sendPaymentRequest,
    handleAddStoreAddressOption,
    refetchLogisticsMainTypes,
    refetchLogisticMethods,
    handleSelectAll,
    handleSelectItem,
    memberSelectedParameters,
    setMemberSelectedParameters,
    isAddingStoreAddress,
    isDeletingLogisticOption,
    isOnlinePayment,
    invoiceValues,
    invoiceValidate,
    note,
    setNote,
    setIsOnlinePayment,
    setInvoiceValues,
    setInvoiceValidate,
  } = useCheckOut();

  const batchDeleteShoppingCartItemsMutation = useMutation({
    mutationFn: async (items: IShoppingCartItemViewModel[]) => {
      try {
        const shoppingCartItems = [...items];
        if (isLogin) {
          const request: IBatchRemoveItemsFromShoppingCartRequest = {
            requests: [{ shoppingCartItems }]
          };
          return await useBatchRemoveItemsFromShoppingCartApi(request);
        } else {
          const tempRequest: IBatchRemoveItemsFromTempShoppingCartRequest = {
            requests: [{
              sessionId: tryGetSessionId(),
              merchantId: merchantId,
              shoppingCartItems
            }]
          };
          return await useBatchRemoveItemsFromTempShoppingCartApi(tempRequest);
        }
      } catch (error) {
        // Handle cancellation or other errors
        console.error('Deletion cancelled or failed:', error);
        throw error;
      }
    },
    onSuccess: (response, items) => {
      if (response.isSuccess) {
        notificationApi.success({
          message: translate('Operation success'),
          placement: 'bottomLeft',
        });

        sendGAEvent('remove_from_cart', {
          currency: items[0].currency,
          value: items.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
          items: items.map((itemSpec) => ({
            item_id: itemSpec?.itemId.toString(),
            item_name: itemSpec?.itemName,
            affiliation: merchantPortalOptionSettingMutation.data?.result?.merchantName,
            coupon: "None",
            discount: 0,
            index: 0,
            item_brand: merchantPortalOptionSettingMutation.data?.result?.merchantName,
            item_category: itemSpec!.itemSpecName,
            price: itemSpec!.itemSpecPrice,
            quantity: itemSpec.buyAmount
          })),
        });

        setSelectedItems(prevItems =>
          prevItems.filter(i => !items.some(item =>
            (i.itemId.toString() === item.itemId.toString()) &&
            (i.itemSpecId.toString() === item.itemSpecId.toString())
          ))
        );

        shoppingCartDataMutation.mutate();
        refreshShoppingCartItemCount(isLogin);
      } else {
        messageApi.error(translate(response.message || 'Operation failed'));
      }
    }
  });

  const batchAddShoppingCartItemsMutation = useMutation({
    mutationFn: async (items: IShoppingCartItemViewModel[]) => {
      if (isLogin) {
        var request: IBatchAddItemToShoppingCartRequest = {
          addItemToShoppingCartItems: items.map(item => ({
            itemId: item.itemId,
            itemSpecId: item.itemSpecId,
            buyAmount: item.buyAmount,
          })),
          hash: 'hash'
        };
        const st = `${merchantId}:${JSONBig.stringify(request.addItemToShoppingCartItems)}`;
        const sign = await signHelper.generateDynamicTimeSignAsync(st.toLowerCase(), new Date());
        request.hash = sign;
        return await useBatchAddItemsToShoppingCartApi(request);
      }
      else {
        const tempRequest: IBatchAddItemToTempShoppingCartRequest = {
          merchantId: merchantId,
          sessionId: tryGetSessionId(),
          hash: 'hash',
          addItemToShoppingCartItems: items.map(item => ({
            itemId: item.itemId,
            itemSpecId: item.itemSpecId,
            buyAmount: item.buyAmount,
          }))
        };
        const st = `${merchantId}:${tryGetSessionId()}:${JSONBig.stringify(tempRequest.addItemToShoppingCartItems)}`;
        const sign = await signHelper.generateDynamicTimeSignAsync(st.toLowerCase(), new Date());
        tempRequest.hash = sign;
        return await useBatchAddItemToTempShoppingCartApi(tempRequest);
      }
    },
    onSuccess: (response, items) => {
      if (response.isSuccess) {
        notificationApi.success({
          message: translate('Operation success'),
          placement: 'bottomLeft',
        });
      }
    }
  });

  const portalStyle = merchantPortalStyleSettingsMutation.data?.result?.find(
    x => x.type === MerchantPortalStyleSettingType.Pages && x.name === MerchantPortalStyleShopPages.ShoppingCartPage
  );

  const TemplateComponent = useMemo(() => {
    return dynamic<ShoppingCartPageTemplateProps>(() =>
      import(`../../Templates/Shop/Pages/ShoppingCartPage/Template_${portalStyle?.style || '0'}.tsx`),
      {
        loading: () => <LoadingComponent />,
        ssr: false
      }
    );
  }, [portalStyle?.style]);

  const getMerchantPortalOptionSetting = useCallback(() => {
    merchantPortalOptionSettingMutation.mutate({ merchantId: merchantId });
  }, [merchantPortalOptionSettingMutation]);

  useEffect(() => {
    memberLogisticOptionsMutation.mutate({ isLogin });
    shoppingCartDataMutation.mutate();
    getMerchantPortalOptionSetting();
  }, []);

  return (
    <CheckOutProvider
      merchantId={merchantId}
      isLogin={isLogin}
      tryGetSessionId={tryGetSessionId}
      messageApi={messageApi}
      translate={translate}>
      <TemplateComponent
        navBarHeight={navBarHeight}
        navBarGap={navBarGap}
        BASE_PATH={BASE_PATH}
        merchantId={merchantId}
        deviceType={deviceType}
        translate={translate}
        messageApi={messageApi}
        notificationApi={notificationApi}
        isPreviewMode={false}
        config={portalStyle?.styleSetting || {}}
        shoppingCartDataMutation={shoppingCartDataMutation}
        isLogin={isLogin}
        tryGetSessionId={tryGetSessionId}
        shoppingCartItemCount={shoppingCartItemCount}
        refreshShoppingCartItemCount={refreshShoppingCartItemCount}
        paymentMainTypesMutation={paymentMainTypesMutation}
        merchantPortalPaymentSettingsMutation={merchantPortalPaymentSettingsMutation}
        memberLogisticOptionsMutation={memberLogisticOptionsMutation}
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentValues={paymentValues}
        setPaymentValues={setPaymentValues}
        sendPaymentRequest={sendPaymentRequest}
        logisticsMainTypes={logisticsMainTypes}
        logisticMethods={logisticMethods}
        selectedLogistic={selectedLogistic}
        setSelectedLogistic={setSelectedLogistic}
        selectedMemberLogisticOption={selectedMemberLogisticOption}
        setSelectedMemberLogisticOption={setSelectedMemberLogisticOption}
        logisticValues={logisticValues}
        setLogisticValues={setLogisticValues}
        userWantReservePay={userWantReservePay}
        setUserWantReservePay={setUserWantReservePay}
        receiverInfos={receiverInfos}
        setReceiverInfos={setReceiverInfos}
        handleAddMemberLogisticOption={handleAddMemberLogisticOption}
        handleDeleteMemberLogisticOption={handleDeleteMemberLogisticOption}
        handleAddStoreAddressOption={handleAddStoreAddressOption}
        batchDeleteShoppingCartItemsMutation={batchDeleteShoppingCartItemsMutation}
        batchAddShoppingCartItemsMutation={batchAddShoppingCartItemsMutation}
        merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
        merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
        handleSelectAll={handleSelectAll}
        handleSelectItem={handleSelectItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        sendGAEvent={sendGAEvent}
        getCountrySelectionDisplayName={getCountrySelectionDisplayName}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        nextStep={nextStep}
        prevStep={prevStep}
        memberSelectedParameters={memberSelectedParameters}
        setMemberSelectedParameters={setMemberSelectedParameters}
        isAddingStoreAddress={isAddingStoreAddress}
        isDeletingLogisticOption={isDeletingLogisticOption}
        isOnlinePayment={isOnlinePayment}
        invoiceValues={invoiceValues}
        invoiceValidate={invoiceValidate}
        note={note}
        setNote={setNote}
        setIsOnlinePayment={setIsOnlinePayment}
        setInvoiceValues={setInvoiceValues}
        setInvoiceValidate={setInvoiceValidate}
      />
    </CheckOutProvider>
  );
});

export default ShoppingCartPage;
