import { DeviceType } from "@/Templates/enums/templateEnums";
import { IMerchantPortalOptionSettingViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useGetMerchantThirdPartyInvoiceSettingViewModes } from "@/Templates/lib/apis";
import { Empty, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import TaiwanInvoiceFields from "./TaiwanInvoiceFields";

export interface IInvoiceTeampleProps {
    deviceType: DeviceType;
    merchantId: BigInt;
    merchantPortalOptionSetting: IMerchantPortalOptionSettingViewModel | undefined;
    translate: (key: string) => string;
    getCountrySelectionDisplayName: (country: string) => string;
    invoiceValues: any;
    setInvoiceValues: any;
    invoiceValidate: any;
    setInvoiceValidate: any;
}
export interface IInvoiceTeampleRef {

}

const InvoiceTemplate = React.forwardRef((props: IInvoiceTeampleProps, ref: React.ForwardedRef<IInvoiceTeampleRef | undefined>) => {

    const merchantThirdPartyInvoiceSettingViewModelsMutation = useMutation(async (merchantId: BigInt) => await useGetMerchantThirdPartyInvoiceSettingViewModes(merchantId));
    const getInvoiceTemplate = (country: string) => {
        var invoiceSetting = merchantThirdPartyInvoiceSettingViewModelsMutation.data?.result?.find(r => r.supportedCountry === country);
        // 商戶未設定該國家發票
        if (!invoiceSetting) {
            return <Empty></Empty>;
        }
        switch (country) {
            case "TW":
                return (
                    //TW invoice
                    <TaiwanInvoiceFields
                        invoiceValues={props.invoiceValues}
                        setInvoiceValues={props.setInvoiceValues}
                        invoiceValidate={props.invoiceValidate}
                        setInvoiceValidate={props.setInvoiceValidate}></TaiwanInvoiceFields>
                );
            default:
                return (
                    //Unsupport country show antd no data
                    <Empty></Empty>
                );
        }
    }
    const [formWidth, setFormWidth] = useState('50%');

    useEffect(() => {
        merchantThirdPartyInvoiceSettingViewModelsMutation.mutate(props.merchantId);
    }, []);

    useEffect(() => {
        switch (props.deviceType) {
            case DeviceType.Desktop:
            case DeviceType.LapTop:
                setFormWidth('40%');
                break;
            case DeviceType.Mobile:
                setFormWidth('90%');
                break;
        }
    }, [props.deviceType]);

    return (
        <div style={{ margin: '10px' }}>
            {getInvoiceTemplate(props.merchantPortalOptionSetting?.country ?? '')}
            <Flex style={{ margin: '10px' }} justify="center">
                {props.invoiceValidate ? null : <div style={{ color: 'red' }}>{props.translate('Please fill in all required fields')}</div>}
            </Flex>
        </div>
    );
});
export default InvoiceTemplate;