import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import routes from '../../../constants/routes';
import { GlobalContext } from "../../../lib/contexts/GlobalContext";
import AppFooter from "./AppFooter";
import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";

const MyRoutes = () => {
  const {
    navBarGap,
    sendGAEvent,
    isHideNavBar,
    merchantPortalStyleSettingsMutation
  } = React.useContext(GlobalContext);
  const location = useLocation();

  const switchPageFadeTime = merchantPortalStyleSettingsMutation?.data?.result?.find(r =>
    r.type === MerchantPortalStyleSettingType.Components &&
    r.name === MerchantPortalStyleShopComponents.NavBar)?.styleSetting?.switchPageFadeTime ?? 1.5;

  useEffect(() => {
    sendGAEvent('page_view', { page_path: `${location.pathname}` });
  }, [location.pathname]);

  const memoizedRoutes = useMemo(() => (
    <div style={{ paddingTop: isHideNavBar ? 0 : `${navBarGap}px` }}>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 0.99, x: 0 }}
          transition={{ duration: switchPageFadeTime }}
        >
          <Routes location={location}>
            {Object.values(routes).map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
          <AppFooter />
        </motion.div>
      </AnimatePresence>
    </div>
  ), [navBarGap, isHideNavBar, location]);

  return memoizedRoutes;
};

export default MyRoutes;
