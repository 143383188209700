import JSONBig from 'json-bigint';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { BASE_PATH } from '@/constants/routes';
import { CurrencyCode } from '@/enums/CurrecnyCode';
import { useGetItemsByMerchantApi, useGetItemSpecsApi } from '@/lib/api/apis';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { SignHelper } from '@/Templates/helpers/SignHelper';
import { IAddItemToShoppingCartItem, IItemSpec, IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { useBatchAddItemsToShoppingCartApi, useBatchAddItemToTempShoppingCartApi } from '@/Templates/lib/apis';
import { ItemDetailPageTemplateProps } from '@/Templates/TemplateProps';
;

const ItemDetailPage: React.FC = React.memo(() => {
    const signHelper = new SignHelper();
    const {
        sendGAEvent,
        shoppingCartItemCount,
        messageApi,
        isLogin,
        merchantId,
        deviceType,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        tryGetSessionId,
        refreshShoppingCartItemCount,
        notificationApi,
        navBarHeight,
        navBarGap
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const location = useLocation();
    const navigate = useNavigate();

    // 商品相關狀態
    const [itemId, setItemId] = useState<BigInt>(BigInt(0));
    const [item, setItem] = useState<IItemViewModel | undefined>(undefined);

    // 商品規格相關狀態
    const [itemSpecs, setItemSpecs] = useState<IItemSpec[]>([]);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(1);

    // 購物車項目狀態
    const [shoppingCartItem, setShoppingCartItem] = useState<IAddItemToShoppingCartItem>({
        itemId: BigInt(0),
        itemSpecId: BigInt(0),
        buyAmount: 0,
    });

    // 從 URL 獲取商品 ID 並加載商品信息
    useEffect(() => {
        const loadItemData = () => {
            const queryParams = new URLSearchParams(location.search);
            const newItemId = BigInt(queryParams.get('itemId') || '0');

            if (newItemId !== itemId) {
                setItemId(newItemId);
                resetItemState();
                getItems({
                    merchantId: merchantId,
                    page: 1,
                    pageSize: 1,
                    extraItemIds: [newItemId]
                });
            }
        };

        const resetItemState = () => {
            setItem(undefined);
            setItemSpecs([]);
            setSelectedItemSpec(null);
            setPurchaseQuantity(1);
        };

        loadItemData();
    }, [location.search, merchantId]);

    // 獲取商品信息
    const { mutate: getItems, isLoading: isLoadingItems } = useMutation(useGetItemsByMerchantApi, {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                if (response.result.data.length > 0) {
                    setItem(response.result.data[0]);
                    getItemSpecs(merchantId);
                } else {
                    navigate(`/${merchantId}/notfound`);
                }
            }
        }
    });

    // 獲取商品規格
    const { mutate: getItemSpecs } = useMutation(
        async (merchantId: BigInt) => await useGetItemSpecsApi(merchantId, itemId),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    const data = response.result;
                    setItemSpecs(data);
                    setSelectedItemSpec(data[0] ? data[0] : null);
                } else {
                    messageApi.error(response.message || translate('Operation failed'));
                }
            }
        }
    );

    // 生成簽名
    const generateSign = async () => {
        const st = isLogin
            ? `${merchantId}:${JSONBig.stringify([shoppingCartItem])}`
            : `${merchantId}:${tryGetSessionId()}:${JSONBig.stringify([shoppingCartItem])}`;
        console.log(st);
        return await signHelper.generateDynamicTimeSignAsync(st.toLowerCase(), new Date());
    };

    // 處理添加到購物車成功
    const handleAddToCartSuccess = useCallback((data: any) => {
        if (data?.isSuccess) {
            notificationApi.success({
                message: translate('Operation success'),
                placement: 'bottomLeft',
            });
            setPurchaseQuantity(1);
        } else {
            notificationApi.error({
                message: translate(data?.message),
                placement: 'bottomLeft',
            });
        }
    }, [notificationApi, translate]);

    // 添加到臨時購物車
    const addToTempShoppingCartMutation = useMutation(
        async () => await useBatchAddItemToTempShoppingCartApi({
            merchantId: merchantId,
            sessionId: tryGetSessionId(),
            addItemToShoppingCartItems: [shoppingCartItem],
            hash: await generateSign()
        }),
        {
            onSuccess: handleAddToCartSuccess
        }
    );

    // 添加到購物車
    const addToShoppingCartMutation = useMutation(
        async () => await useBatchAddItemsToShoppingCartApi({
            addItemToShoppingCartItems: [shoppingCartItem],
            hash: await generateSign()
        }),
        {
            onSuccess: handleAddToCartSuccess
        }
    );

    // 處理規格選擇變更
    const handleSelectChange = useCallback((value: string) => {
        const itemSpecId = BigInt(value);
        const itemSpec = itemSpecs.find(item => item.id.toString() === itemSpecId.toString());
        setSelectedItemSpec(itemSpec || null);
        setPurchaseQuantity(itemSpec?.stockAmount || 0 > 0 ? 1 : 0);
    }, [itemSpecs]);

    // 更新購物車項目
    useEffect(() => {
        setShoppingCartItem({
            itemId: item?.id || BigInt(0),
            itemSpecId: selectedItemSpec?.id || BigInt(0),
            buyAmount: purchaseQuantity,
        });
    }, [purchaseQuantity, selectedItemSpec, item]);

    // 處理 meta tags
    useEffect(() => {
        const metaTags: { name?: string; property?: string; content: string }[] = [];

        // 如果有商品名稱，加入 title meta tag
        if (item?.name) {
            metaTags.push({ property: 'og:title', content: item.name });
            metaTags.push({ name: 'twitter:title', content: item.name });
            metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
            metaTags.push({ name: 'twitter:url', content: window.location.origin });
        }

        // 如果有圖片且至少一張，加入 image meta tag 
        if (item?.coverImages?.length && item?.coverImages?.length >= 1) {
            metaTags.push({ property: 'og:image', content: item.coverImages[0] });
            metaTags.push({ name: 'twitter:image', content: item.coverImages[0] });
        }

        // 只有在有 meta tags 時才執行
        if (metaTags.length > 0) {
            const addedTags = metaTags.map(tag => {
                const metaTag = document.createElement('meta');
                if (tag.property) {
                    metaTag.setAttribute('property', tag.property);
                }
                if (tag.name) {
                    metaTag.setAttribute('name', tag.name);
                }
                metaTag.setAttribute('content', tag.content || '');
                document.head.appendChild(metaTag);
                return metaTag;
            });

            return () => {
                addedTags.forEach(tag => document.head.removeChild(tag));
            };
        }
    }, [item]);

    // 處理添加到購物車
    const handleAddToShoppingCart = useCallback(async () => {
        sendGAEvent('add_to_cart', {
            currency: selectedItemSpec?.currency || CurrencyCode.TWD,
            value: shoppingCartItem.buyAmount * (selectedItemSpec?.sellPrice || 0),
            items: [
                {
                    item_id: item?.id.toString(),
                    item_name: item?.name,
                    affiliation: merchantPortalOptionSettingMutation.data?.result?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSettingMutation.data?.result?.merchantName,
                    item_category: selectedItemSpec?.name,
                    price: selectedItemSpec?.sellPrice,
                    quantity: shoppingCartItem.buyAmount,
                }
            ]
        });
        if (isLogin) {
            await addToShoppingCartMutation.mutateAsync();
        } else {
            await addToTempShoppingCartMutation.mutateAsync();
        }
        await refreshShoppingCartItemCount(isLogin);
    }, [sendGAEvent, shoppingCartItem, selectedItemSpec, item, isLogin, addToShoppingCartMutation, addToTempShoppingCartMutation, refreshShoppingCartItemCount, merchantPortalOptionSettingMutation.data?.result?.merchantName]);

    const portalStyle = useMemo(() => merchantPortalStyleSettingsMutation.data?.result?.find(
        x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'ItemDetailPage'
    ), [merchantPortalStyleSettingsMutation.data?.result]);

    const TemplateComponent = useMemo(() => dynamic<ItemDetailPageTemplateProps>(() =>
        import(`../../Templates/Shop/Pages/ItemDetailPage/Template_${portalStyle?.style || '0'}.tsx`),
        {
            loading: () => <LoadingComponent />,
            ssr: false
        }
    ), [portalStyle?.style]);

    if (isLoadingItems || !item) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            deviceType={deviceType}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            translate={translate}
            isPreviewMode={false}
            messageApi={messageApi}
            notificationApi={notificationApi}
            item={item}
            config={portalStyle?.styleSetting || {}}
            itemSpecs={itemSpecs}
            selectedItemSpec={selectedItemSpec}
            purchaseQuantity={purchaseQuantity}
            shoppingCartItemCount={shoppingCartItemCount}
            handleSelectChange={handleSelectChange}
            setPurchaseQuantity={setPurchaseQuantity}
            handleAddToShoppingCart={handleAddToShoppingCart}
            isAddToShoppingCartLoading={addToShoppingCartMutation.isLoading}
            isAddToTempShoppingCartLoading={addToTempShoppingCartMutation.isLoading}
        />
    );
});

export default ItemDetailPage;
