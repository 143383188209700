import { Button } from 'antd';
import lineIcon from '../../assets/Line/LINE_Brand_icon.png';

export interface ILineLoginProps {
    isLoading: boolean;
}

const LineLogin = (props: ILineLoginProps) => {
    const clientId = process.env.LineClientId;
    const redirectUri = `${process.env.LineRedirectUri}`;
    const state = window.location.origin !== new URL(redirectUri).origin ? `${encodeURI(window.location.origin)}` : `${encodeURI(window.location.href.replace('/login', ''))}`;
    const scope = 'profile%20openid%20email'; // Scopes you want to request
    const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    return (
        <Button
            loading={props.isLoading}
            icon={<img width={20} src={lineIcon}></img>}
            onClick={() => {
                window.location.href = lineLoginUrl
            }}>
            LINE
        </Button>
    );
};

export default LineLogin;
