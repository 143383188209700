import { IOperationResultT, IPortalMerchantThirdPartyPaymentSettingViewModel, IThirdPartyPaymentCreateRespnse } from '@/Templates/interfaces/templatesInterfaces';
import { Button, Col, Divider, Flex, Row, Tabs, Typography } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React, { useEffect, useImperativeHandle } from 'react';
import { UseMutationResult } from 'react-query';
import './PaymentSelector.css';

const { TabPane } = Tabs;
const { Title } = Typography;

export interface IPaymentSelectorProps {
    translate: (key: string) => string;
    messageApi: MessageInstance;
    merchantId: BigInt;
    nextStep?: () => void;
    prevStep?: () => void;
    isHideSteps?: boolean | undefined;
    orderId?: BigInt;
    paymentMainTypesMutation: UseMutationResult<IOperationResultT<string[]>, unknown, {
        signal?: AbortSignal;
    }, unknown>;
    merchantPortalPaymentSettingsMutation: UseMutationResult<IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>, unknown, {
        merchantId: BigInt;
        signal?: AbortSignal;
    }, unknown>;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    setSelectedPayment: (payment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined) => void;
    paymentValues: Record<string, string>;
    setPaymentValues: (values: any) => void;
    sendPaymentRequest: UseMutationResult<IOperationResultT<IThirdPartyPaymentCreateRespnse> | undefined, unknown, {
        orderId: BigInt;
        paymentValues: Record<string, string>;
        selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    }, unknown>;
    isOnlinePayment: boolean;
    setIsOnlinePayment: (isOnlinePayment: boolean) => void;
    isLogin: boolean;
}

export interface IPaymentSelectorRef {
    reset: () => void;
}

const PaymentSelector = React.forwardRef((props: IPaymentSelectorProps, ref: React.ForwardedRef<IPaymentSelectorRef | undefined>) => {
    useEffect(() => {
        if (props.merchantId && props.isLogin) {
            props.merchantPortalPaymentSettingsMutation.mutate({
                merchantId: props.merchantId,
            });
            props.paymentMainTypesMutation.mutate({});
        }
    }, [props.merchantId, props.isLogin]);

    const renderPaymentMethods = () => {
        if (!props.merchantPortalPaymentSettingsMutation.data || !props.paymentMainTypesMutation.data) return null;

        return (
            <div>
                {props.paymentMainTypesMutation.data?.result
                    ?.filter(paymentMainType =>
                        props.merchantPortalPaymentSettingsMutation.data?.result?.some(payment => payment.paymentMainType === paymentMainType) &&
                        paymentMainType !== 'None')
                    .map((paymentMainType) => (
                        <React.Fragment key={paymentMainType}>
                            <Divider>{props.translate(paymentMainType)}</Divider>
                            <Row justify="center" gutter={[16, 16]}>
                                <Col span={24}>
                                    <Flex justify='center' align='center' gap={'small'} wrap='wrap'>
                                        {props.merchantPortalPaymentSettingsMutation.data?.result
                                            ?.filter(payment => payment.paymentMainType === paymentMainType)
                                            .map((payment) => (
                                                <Button
                                                    key={payment.thirdPartySettingId.toString()}
                                                    type={payment === props.selectedPayment ? 'primary' : 'default'}
                                                    onClick={() => {
                                                        props.setSelectedPayment(payment);
                                                        props.setPaymentValues({});
                                                    }}
                                                >
                                                    {payment.providerName}
                                                </Button>
                                            ))}
                                    </Flex>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
            </div>
        );
    };

    const previousStepBtn = () => {
        props.setSelectedPayment(undefined);
        props.setPaymentValues({});
        props.prevStep && props.prevStep();
    }

    const nextBtn = () => {
        props.nextStep && props.nextStep();
    }

    const validateRequest = (): boolean => {
        return !!(props.selectedPayment && props.orderId);
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            props.setSelectedPayment(undefined);
            props.setPaymentValues({});
        }
    }), []);

    return (
        <Flex
            data-testid='payment-selector'
            justify="center">
            <Row style={{ width: '80%' }}>
                <Col span={24}>
                    <Tabs centered defaultActiveKey="1" onChange={(key) => props.setIsOnlinePayment(key === '1')}>
                        <TabPane tab={props.translate('Online Payment')} key="1">
                            {renderPaymentMethods()}
                        </TabPane>
                    </Tabs>
                </Col>
                <Col style={{ marginTop: '20px' }} xs={24}>
                    {!props.isHideSteps && (
                        <Flex justify="center" gap={10}>
                            <Button shape="round" danger onClick={previousStepBtn}>
                                {props.translate('Previous Step')}
                            </Button>
                            <Button shape="round" type="primary" disabled={!props.selectedPayment} onClick={nextBtn}>
                                {props.translate('Next Step')}
                            </Button>
                        </Flex>
                    )}
                    {props.orderId && (
                        <Flex justify="center" style={{ margin: '10px' }}>
                            <Button
                                shape="round"
                                type="primary"
                                disabled={!validateRequest()}
                                loading={props.sendPaymentRequest.isLoading}
                                onClick={() => {
                                    props.sendPaymentRequest.mutate({
                                        orderId: props.orderId!,
                                        paymentValues: props.paymentValues,
                                        selectedPayment: props.selectedPayment
                                    }, {
                                        onSuccess: (response) => {
                                            if (!response?.isSuccess) {
                                                props.messageApi.error(response?.message);
                                            }
                                        }
                                    });
                                }}
                            >
                                {props.translate('Send')}
                            </Button>
                        </Flex>
                    )}
                </Col>
            </Row>
        </Flex>
    );
});

export default PaymentSelector;
