import { BASE_PATH } from '@/constants/routes';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import Footer from '@/Templates/components/Footer';
import React, { useContext } from 'react';

const AppFooter: React.FC = () => {
    const {
        merchantId,
        isHideFooter,
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        navBarHeight,
        navBarGap,
        deviceType,
        messageApi,
        notificationApi
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const buildTime = process.env.BUILD_TIME;

    if (isHideFooter) {
        return null;
    }

    return (
        <Footer
            isHideFooter={isHideFooter}
            navBarHeight={navBarHeight}
            navBarGap={navBarGap}
            deviceType={deviceType}
            BASE_PATH={BASE_PATH}
            merchantId={merchantId}
            translate={translate}
            merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
            merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
            messageApi={messageApi}
            notificationApi={notificationApi}
            buildTime={buildTime || ''}
        />
    );
};

export default AppFooter;
