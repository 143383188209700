import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';

interface QRCodePageProps {
    data: string;
}

export const QRCodePage: React.FC = () => {
    const { setIsHideNavBar, setIsHideAnnouncement } = React.useContext(GlobalContext);
    const params = new URLSearchParams(window.location.search);
    const data = decodeURIComponent(params.get('data') || '');

    React.useEffect(() => {
        setIsHideNavBar(true);
        setIsHideAnnouncement(true);

        return () => {
            setIsHideNavBar(false);
            setIsHideAnnouncement(false);
        };
    }, [setIsHideNavBar, setIsHideAnnouncement]);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md">
                <QRCodeSVG
                    value={data}
                    size={256}
                    level="H"
                    includeMargin={true}
                />
            </div>
        </div>
    );
};

export default QRCodePage; 