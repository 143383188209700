import JSONBig from 'json-bigint';

export class LocalStorageHelper {

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    setWithExpiry(key: string, value: any, ttl: number) {
        if (ttl <= 0) {
            this.removeItem(key);
        }
        const now = new Date();
        const item = {
            value: JSONBig.stringify(value),
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSONBig.stringify(item));
    }

    getWithExpiry<TData = unknown>(key: string) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }

        const item = JSONBig.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        const result = JSONBig.parse(item.value);
        return result as TData;
    }
}