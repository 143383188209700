import { BASE_PATH, IsFreeDomain } from '@/constants/routes';
import { LoginTypeEnum, ThirdPartyLoginProviders } from '@/enums/Enums';
import { ILoginRequest, IPortalThirdPartyLoginRequest } from "@/interfaces/Requests";
import { useMemberLoginApi } from "@/lib/api/apis";
import { useThirdPartyLoginApi } from "@/lib/api/thirdPartyLogin";
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from '@/Templates/enums/templateEnums';
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Form, Image, InputRef, Tabs } from "antd";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ThirdPartyLogin from "./components/ThirdPartyLogin";
// 登入頁面組件
const LoginPage = () => {
    const {
        merchantPortalOptionSettingMutation,
        merchantPortalStyleSettingsMutation,
        sendGAEvent,
        messageApi,
        setIsLogin,
        merchantId,
        refreshShoppingCartItemCount,
        tryGetSessionId,
        deviceType,
        setIsHideNavBar,
        setIsHideFooter,
        isHideFooter,
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const emailRef = useRef<InputRef>(null);

    // 新增 activeTab state
    const [activeTab, setActiveTab] = useState<LoginTypeEnum>(LoginTypeEnum.Email);

    // 初始化登入請求狀態
    const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
        loginType: LoginTypeEnum.Email,
        email: `${process.env.TEST_ACCOUNT}`,
        hashPassword: process.env.TEST_PASSWORD || "",
        phoneNumber: "",
        merchantId: merchantId,
        sessionId: tryGetSessionId()
    });

    console.log(process.env.TEST_PASSWORD);

    // 處理 Tab 切換
    const handleTabChange = (key: string) => {
        setActiveTab(key as LoginTypeEnum);
        setLoginRequest({
            ...loginRequest,
            loginType: key as LoginTypeEnum,
            email: key === LoginTypeEnum.Email ? loginRequest.email : "",
            phoneNumber: key === LoginTypeEnum.PhoneNumber ? loginRequest.phoneNumber : ""
        });
    };

    // 處理登入響應
    const loginResponseHandler = async (data: any, loginType: string) => {
        if (data?.isSuccess) {
            sendGAEvent('login', { method: loginType });
            localStorage.setItem("JWTToken", data.result);
            setIsLogin(true);
            messageApi.success(translate('Operation success'));
            await refreshShoppingCartItemCount(true);
            navigate(`${BASE_PATH}/`, { replace: true });
        } else {
            messageApi.error(data?.message);
        }
    };

    // 登入相關的 mutations
    const { mutate: loginMutate, isLoading: loginMutating } = useMutation(
        async (request: ILoginRequest) => {
            return await useMemberLoginApi(request);
        },
        {
            onSuccess: async (data) => {
                await loginResponseHandler(data, 'MicroShop');
            },
        }
    );

    const { mutate: thirdPartyLoginMutate, isLoading: thirdPartyLoginMutating } = useMutation(
        async (request: IPortalThirdPartyLoginRequest) => {
            window.history.replaceState({}, document.title, window.location.pathname);
            return await useThirdPartyLoginApi(request);
        },
        {
            onSuccess: async (data, request) => {
                await loginResponseHandler(data, request.provider.toString());
            }
        }
    );
    const originHost = IsFreeDomain ? `${window.location.origin}/${merchantId}` : window.location.origin;
    // 第三方登入處理
    const googleLoginBtn = useGoogleLogin({
        flow: 'auth-code',
        redirect_uri: process.env.GoogleRedirectUri,
        state: originHost,
        ux_mode: 'redirect',
        onError: console.log
    });

    const facebookLogin = (token: string, state: string) => {
        thirdPartyLoginMutate({
            merchantId,
            provider: ThirdPartyLoginProviders.Facebook,
            accessToken: token,
            sessionId: tryGetSessionId(),
            extraData: state
        });
    };

    const handleGoogleLogin = (token: string, state: string) => {
        thirdPartyLoginMutate({
            merchantId,
            provider: ThirdPartyLoginProviders.Google,
            accessToken: token,
            sessionId: tryGetSessionId(),
            extraData: process.env.GoogleRedirectUri
        });
    }

    const handleLineLogin = (token: string) => {
        thirdPartyLoginMutate({
            merchantId,
            provider: ThirdPartyLoginProviders.Line,
            accessToken: token,
            sessionId: tryGetSessionId(),
            extraData: process.env.LineRedirectUri
        });
    };

    const handleThirdPartyTokenLogin = (token: string, method: string, state?: string) => {
        switch (method) {
            case 'line':
                handleLineLogin(token);
                break;
            case 'facebook':
                facebookLogin(token, state || '');
                break;
            case 'google':
                handleGoogleLogin(token, state || '');
                break;
            default:
                messageApi.error(translate('Invalid login method'));
                break;
        }
    };

    // 表單處理
    const handelChange = (type: string, value: string) => {
        setLoginRequest({ ...loginRequest, [type]: value });
    };

    const handelSubmit = () => {
        const submitRequest = {
            ...loginRequest,
            hashPassword: MD5(loginRequest.hashPassword).toString().toLowerCase()
        };
        loginMutate(submitRequest);
    };

    useEffect(() => {
        setIsHideNavBar(true);
        setIsHideFooter(true);
        return () => {
            setIsHideNavBar(false);
            setIsHideFooter(false);
        }
    }, []);

    useEffect(() => {
        if (!isHideFooter) {
            setIsHideFooter(true);
        }
    }, [isHideFooter]);

    // 處理 meta tags
    useEffect(() => {
        const metaTags: { property: string; content: string }[] = [];

        // 如果有商品名稱，加入 title meta tag
        if (merchantPortalOptionSettingMutation.data?.result?.merchantName) {
            metaTags.push({ property: 'og:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Login')}` });
            // Twitter meta tags
            metaTags.push({ property: 'twitter:title', content: `${merchantPortalOptionSettingMutation.data?.result?.merchantName} - ${translate('Login')}` });
            metaTags.push({ property: 'twitter:card', content: 'summary' });
        }
        if (merchantPortalOptionSettingMutation.data?.result?.merchantIconPath) {
            metaTags.push({ property: 'og:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
            metaTags.push({ property: 'twitter:image', content: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath });
        }

        // 只有在有 meta tags 時才執行
        if (metaTags.length > 0) {
            const addedTags = metaTags.map(tag => {
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('property', tag.property);
                metaTag.setAttribute('content', tag.content || '');
                document.head.appendChild(metaTag);
                return metaTag;
            });

            return () => {
                addedTags.forEach(tag => document.head.removeChild(tag));
            };
        }
    }, [merchantPortalOptionSettingMutation.data?.result, translate]);

    return (
        <div className={`flex justify-center items-center h-[100vh]`}>
            <div className={`bg-white p-8 rounded-lg shadow-[0_-10px_50px_-12px_rgba(0,0,0,0.25),0_10px_50px_-15px_rgba(0,0,0,0.3)] w-full max-w-md ${deviceType === DeviceType.Mobile ? 'h-[100vh]' : 'h-auto'}`}>
                <Flex justify="center" align="middle" className="mb-6">
                    <Image
                        onClick={() => navigate(`${BASE_PATH}/`)}
                        src={merchantPortalOptionSettingMutation.data?.result?.merchantIconPath}
                        width={100}
                        preview={false}
                    />
                </Flex>

                <Tabs
                    centered
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    items={[
                        {
                            key: LoginTypeEnum.Email,
                            label: translate('Email Login'),
                            children: (
                                <LoginForm
                                    form={form}
                                    emailRef={emailRef}
                                    loginRequest={loginRequest}
                                    handelChange={handelChange}
                                    handelSubmit={handelSubmit}
                                    isLoading={thirdPartyLoginMutating || loginMutating}
                                    translate={translate}
                                    merchantId={merchantId}
                                    loginType={LoginTypeEnum.Email}
                                />
                            )
                        },
                        {
                            key: LoginTypeEnum.PhoneNumber,
                            label: translate('Phone Login'),
                            children: (
                                <LoginForm
                                    form={form}
                                    emailRef={emailRef}
                                    loginRequest={loginRequest}
                                    handelChange={handelChange}
                                    handelSubmit={handelSubmit}
                                    isLoading={thirdPartyLoginMutating || loginMutating}
                                    translate={translate}
                                    merchantId={merchantId}
                                    loginType={LoginTypeEnum.PhoneNumber}
                                />
                            )
                        }
                    ]}
                />

                <Divider>{translate('Third Party') + ' ' + translate('Login')}</Divider>

                <ThirdPartyLogin
                    isLoading={thirdPartyLoginMutating || loginMutating}
                    googleLoginBtn={() => googleLoginBtn()}
                    handleThirdPartyTokenLogin={handleThirdPartyTokenLogin}
                    translate={translate}
                    merchantId={merchantId}
                    tryGetSessionId={() => tryGetSessionId().toString()}
                    deviceType={deviceType}
                />

                <Divider />
                <Flex wrap="wrap" justify="center" align="center" gap={16}>
                    {translate('New to our platform') + '?'}
                    <Button
                        type="link"
                        onClick={() => navigate(`${BASE_PATH}/register`)}
                        className="ml-2"
                    >
                        {translate('Register now')}
                    </Button>
                </Flex>
            </div>
        </div>
    );
};

export default LoginPage; 